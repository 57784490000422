import { useContext } from 'react';

import { Box } from '@chakra-ui/react';
import Head from 'next/head';
import KustomComponent from '@/lib/kustomcms-sdk/lib/components/KustomComponent';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';
import PagesContext from '@/lib/kustomcms-sdk/lib/contexts/pages';
import loaderAnimationData from '../lotties/loader.json';
import SettingsContext from '@/lib/kustomcms-sdk/lib/contexts/settings';
import { AtomicComponent } from '@/lib/kustomcms-sdk/lib/types';

export const loaderLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: loaderAnimationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const HOME_PAGECODE = '4onpu';

export default function Home() {
  const pagesContext = useContext(PagesContext);
  const langContext = useContext(LanguageContext);

  const { settings } = useContext(SettingsContext);
  const urlSite = settings?.system?.clientUrl;

  const content = pagesContext.pages.find(
    (page) =>
      page.pageCode === HOME_PAGECODE || page.objectCode === HOME_PAGECODE,
  );

  const openGraphTitle = content?.metaTitle?.[langContext.currentLang || 'fr'];
  const openGraphDescription =
    content?.metaDescription?.[langContext.currentLang || 'fr'];

  return (
    <Box>
      <Head>
        <title>
          {content?.metaTitle?.[langContext.currentLang || 'fr'] ||
            'Les Elmes Hotel'}
        </title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1"
        />
        <meta
          name="description"
          content={content?.metaDescription?.[langContext.currentLang || 'fr']}
        />

        <meta property="og:title" content={openGraphTitle} />
        <meta property="og:description" content={openGraphDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={urlSite} />
        <meta
          property="og:image"
          content={`${urlSite}/og-hotel-les-elmes.jpg`}
        />
      </Head>
      {content?.components?.map((component) => (
        <KustomComponent
          key={component.id}
          component={component}
          page={content}
        />
      ))}
    </Box>
  );
}
